.default-table-container {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.dashboard-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #F4F5F7;
    border-radius: 8px;
    margin-bottom: 20px;
}

.search-date {
    flex: 1;
    padding-right: 10px;
}

.filter-options {
    display: flex;
    flex: 2;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.filter-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filter-label {
    margin-right: 5px;
}

.filter-select {
    padding: 5px;
    border-radius: 4px;
    border-color: #ccc;
    min-width: 120px;
}

.filter-icons {
    display: flex;
    align-items: center;
    gap: 15px;
}

.filter-icon {
    cursor: pointer;
    padding: 8px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-header-cell {
    border-bottom: 1px solid #e2e8f0;
    padding: 8px;
}

.table-body {
    padding: 10px;
}
