.pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15%;
    margin: 15px 25px;

    .active {
        background-color: #EF805D;
        color: #fff;
    }
    .page-button {
        background-color: #F5F5F5;
        color: #404B52;
        border: 1px solid #EEEEEE;
    }

    .pagination-text {
        font-size: 14px;
        color: #B5B7C0;
        font-weight: 500;
    }

    .pagination-buttons {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}