 .button-container{
    display: flex;
    margin-top: 30px;
    justify-content: end;
}
.button-container .button{
    padding: 15px;
    width: 140px;
    border: 1px solid #ed805f;
    background: white;
    color: #ed805f;
    border-radius: 6px;
}
.button-container .button-active{
    padding: 15px;
    margin-left: 10px;
    width: 140px;
    background: #ed805f;
    border: 1px solid #ed805f;
    color: white;
    border-radius: 6px;
}
/* Add this CSS in your global stylesheet or in a CSS-in-JS solution if you prefer */
input[type="checkbox"] {
  position: relative;
  width: 42px;
  height: 24px;
  appearance: none;
  background-color: #ccc;
  outline: none;
  cursor: pointer;
  border-radius: 24px;
  transition: background-color 0.3s ease-in-out;
}

input[type="checkbox"]::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

input[type="checkbox"]:checked {
  background-color: #EF805D;
}

input[type="checkbox"]:checked::before {
  transform: translateX(18px);
}
