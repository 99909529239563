.behaviour-container {

    @mixin arrowIcon ($fontSize, $color) {
        font-size: $fontSize;
        color: $color;
    }

    display: flex;
    padding: 10px;
    gap: 34px;

    .behaviour-left-section {
        width: 30%;

        .card-section {
            padding: 1.875rem 2.1875rem; 
        
            .date-class-container {
                display: flex;
                flex-wrap: wrap; 
                align-items: center;
                column-gap: 0.625rem; 
                width: 100%; 
        
                .search-date {
                    display: flex;
                    align-items: center;
                    column-gap: 0.625rem; 
                    width: 100%; 
        
                    .input-dates {
                        position: relative;
                        flex: 1; 
                        min-width: 150px; 
        
                        input {
                            padding: 0.75rem 1.3125rem; 
                            border-radius: 0.375rem; 
                            font-size: 0.8125rem; 
                            width: 100%; 
                        }
                    }
        
                    .input-label::before {
                        font-size: 0.625rem; 
                        color: #aaa8a8;
                        position: absolute;
                    }
        
                    .input-dates:first-child .input-label::before {
                        content: 'From Date';
                        top: 0;
                        left: 0;
                    }
        
                    .input-dates:last-child .input-label::before {
                        content: 'To Date';
                        top: 0;
                        left: 0;
                    }
                }
            }
        
            .class-info-section {
                display: flex;
                flex-wrap: wrap; 
                padding-top: 10px;
        
                .class-details {
                    width: 40%; 
                    min-width: 200px; 
        
                    .details-container {
                        .value {
                            font-size: 1.3125rem; 
                            color: #000;
                            font-weight: 600;
                        }
                        .title {
                            font-size: 0.875rem; 
                            font-weight: 300;
                            color: #898888;
                        }
                    }
                }
        
                .class-info-image {
                    width: 60%; 
                    min-width: 200px; 
                    background-color: rgb(219, 219, 219);
                }
            }
        
            .section-divider {
                background-image: linear-gradient(80deg, #CECECE, #000, #CECECE);
                height: 1px;
                margin-top: 16px;
            }
        
            .class-sections-container {
                .section-card {
                    margin-top: 22px;
                    padding: 25px 15px;
        
                    .section-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
        
                        .section-title {
                            font-size: 1rem; 
                            font-weight: 600;
                            color: #000000;
                        }
        
                        .collaborative-content {
                            position: relative;
        
                            .collaborative-text {
                                padding: 4px 22px;
                                border-radius: 23px;
                                font-size: 0.75rem; 
                                background: linear-gradient(80deg, #56AC48, #3956BD, #E459E7);
                                background-clip: text;
                                -webkit-text-fill-color: transparent;
                                font-weight: 600;
                                position: relative;
                                z-index: 2;
                                bottom: 1px;
                            }
        
                            .collaborative-border {
                                &:before {
                                    content: '';
                                    border-radius: 23px;
                                    background-image: linear-gradient(80deg, #56AC48, #3956BD, #E459E7);
                                    padding: 10px;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    left: 0px;
                                    position: absolute;
                                }
        
                                &::after {
                                    content: '';
                                    background: inherit;
                                    position: absolute;
                                    top: 1px;
                                    width: 98%;
                                    height: 92%;
                                    left: 1px;
                                    border-radius: 23px;
                                }
                            }
                        }
                    }
        
                    .class-details {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 16px;
        
                        .class-strength {
                            .attendance {
                                span {
                                    font-weight: 600;
                                    color: #000;
                                }
                                span:first-child {
                                    font-size: 2.1875rem; 
                                }
                                span:nth-child(2) {
                                    font-size: 1rem; 
                                }
                            }
                            .title {
                                color: #898888;
                                font-size: 0.875rem; /* 14px to rem */
                            }
                        }
        
                        .class-teacher {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
        
                            .teacher-img {
                                width: 2.5rem; /* 40px to rem */
                                height: 2.5rem; /* 40px to rem */
                                background-color: #FFFFFF;
                                border-radius: 50%;
                                overflow: hidden;
                                margin-top: 0.75rem; /* 12px to rem */
                            }
                            .teacher-title {
                                color: #898888;
                                font-size: 0.875rem; /* 14px to rem */
                            }
                        }
                    }
                }
            }
        }
        
        /* Media Queries for Smaller Screens */
        @media (max-width: 768px) {
            .card-section {
                padding: 1.5rem; /* Reduce padding on smaller screens */
        
                .date-class-container {
                    flex-direction: column; /* Stack date inputs vertically */
                    align-items: flex-start;
        
                    .search-date {
                        flex-direction: column; /* Stack search date inputs vertically */
                        width: 100%;
                    }
        
                    .input-dates {
                        min-width: 100%; /* Ensure full width */
                    }
                }
        
                .class-info-section {
                    flex-direction: column; /* Stack class info section vertically */
                    width: 100%; /* Ensure full width */
                }
        
                .class-details,
                .class-info-image {
                    width: 100%; /* Make class details and image full width */
                    min-width: unset; /* Remove min width */
                }
        
                .class-sections-container .section-card {
                    padding: 1rem; /* Reduce padding on smaller screens */
                }
        
                .class-details .class-strength,
                .class-teacher {
                    width: 100%; 
                }
            }
        }  
    }

    .behaviour-right-section {
        width: 70%;
        padding-top: 40px;

        .tab-active {
            color: #EF805D;
            border-bottom: 2px solid #EF805D;
        }
        .tab-content-container {
            display: flex;
            justify-content: space-between;
        
            .columns {
                display: flex;
                width: 100%;
                gap: 1rem; /* Add gap between columns */
        
                .column-1 {
                    width: 68%; /* Make column 1 wider */
                }
        
                .column-2 {
                    width: 32%; 
                    display: flex;
                    flex-direction: column;
                    height: 100%;
        
                    .graph-card {
                        background: #fff; /* Card background */
                        border-radius: 12px;
                        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for card effect */
                        padding: 1rem;
                    }
        
                    /* Set specific heights for each card */
                    .card-1 {
                        height: 25%; /* First card takes 25% of the height */
                    }
        
                    .card-2 {
                        height: 35%; /* Second card takes 35% of the height */
                    }
        
                    .card-3 {
                        height: 40%; /* Third card takes 40% of the height */
                    }
                }
            }
        
            .row {
                display: flex;
                justify-content: space-between;
        
                .graph-card {
                    width: 48%; /* Make each card take half of the row */
                    background: #fff; /* Card background */
                    border-radius: 12px;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                    padding: 20px;
                    margin-bottom: 20px; /* Add space between cards */
                }
            }
        
            .graph-card {
                background: #fff; /* Card background */
                border-radius: 12px;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for card effect */
                padding: 20px;
                margin-bottom: 20px;
        
                .card-name {
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
        
                .emotional-emojis {
                    padding: 0 18px;
        
                    .emotions {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 15px 0;
                        position: relative;
        
                        .icon {
                            span:first-child {
                                font-size: 20px;
                                padding-right: 16px;
                            }
        
                            span:last-child {
                                font-size: 16px;
                                color: #767676;
                                font-weight: 500;
                            }
                        }
        
                        .number {
                            font-size: 20px;
                            color: #131313;
                            font-weight: 500;
                        }
        
                        &:not(:last-child):after {
                            content: '';
                            position: absolute;
                            height: 1px;
                            width: 100%;
                            background-image: linear-gradient(80deg, #6666661a, #000, #6666660a);
                            bottom: 0;
                        }
                    }
                }
            }

            .stretch-card {
                padding: 1rem;
                background: #fff; /* Card background */
                border-radius: 12px;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                margin-bottom: 20px; /* Space between cards */
                overflow: hidden; /* Hide overflow from the card */
                width: auto; /* Allow the card to expand based on content */
                min-width: 250px; /* Minimum width for better display */
            }
            
            .card-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .card-name {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
            }
    
            
            .card-title {
                display: flex;
                align-items: center;
            }
            
            .dropdown-arrow {
                cursor: pointer;
                margin-left: 0.5rem; /* Space between title and dropdown */
                font-size: 1rem; /* Adjust size as needed */
            }
            
            .subtitle {
                font-size: 0.875rem; /* Lighter font size for subtitle */
                color: #785AFF; /* Lighter color */
                margin-top: 0.25rem; /* Space between title and subtitle */
            }
            
            .student-list {
                margin-top: 1rem; /* Space between title and student list */
                max-height: 12rem; /* Set fixed height for scrolling */
                overflow-y: auto; /* Enable vertical scrolling */
                scrollbar-width: thin; /* For Firefox */
                scrollbar-color: #808080 transparent; /* For Firefox */
                border-radius: 5px;
                max-width: 17.275rem;
                padding-right: 1.8rem; /* Add padding to create space for scrollbar */
            }
            
            /* For WebKit browsers (Chrome, Safari) */
            .student-list::-webkit-scrollbar {
                width: 0.375rem; /* Reduced width of the scrollbar */
                background: transparent; /* Background of the scrollbar */
            }
            
            .student-list::-webkit-scrollbar-thumb {
                background: #808080; /* Color of the scrollbar */
                border-radius: 0.375rem; /* Border radius for the scrollbar */
            }
            
            .student-list::-webkit-scrollbar-thumb:hover {
                background: #34D399; /* Change color on hover */
            }
            
            
            .student-item {
                display: flex;
                flex-direction: column;
                margin-bottom: 0.5rem; /* Space between students */
            }
            
            .student-name {
                font-weight: bold;
                font-size: 0.875rem; /* Font size in rem for student names */
            }
            
            .progress-bar {
                background: #e0e0e0; /* Background of the progress bar */
                border-radius: 5px;
                height: 0.7rem; /* Height of the progress bar */
                position: relative;
                margin-top: 0.25rem; /* Space between name and progress bar */
            }
            
            .progress {
                height: 100%;
                background: #785AFF; 
                border-radius: 5px;
            }
            
            .performance-percentage {
                font-size: 0.875rem; /* Lighter font size for performance percentage */
                color: #785AFF; /* Lighter color */
                margin-top: 0.25rem; /* Space between progress bar and percentage */
            }
            
        
            @media only screen and (max-width: 480px) {
                .graph-card {
                    .card-name {
                        font-size: 0.875rem; // Even smaller font for mobile
                        padding: 0.75rem 0 0.375rem 0.75rem;
                    }
        
                    .emotional-emojis {
                        .emotions {
                            .icon {
                                span:first-child {
                                    font-size: 1rem; // Smaller emojis for mobile
                                }
        
                                span:last-child {
                                    font-size: 0.75rem; // Smaller text for mobile
                                }
                            }
        
                            .number {
                                font-size: 0.875rem; // Smaller numbers for mobile
                            }
                        }
                    }
                }
            }
        }
         

    }
}