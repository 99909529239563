
.menu-container {
    background: #fff;

    .transpoze-logo-container {
        padding: 26px 30px;
            display: flex;
            gap: 16px;
            align-content: center;
            align-items: center;
    }

    .transpoze-logo {
        width: 25px;
        height: 25px;
        border-radius: 8px;
        margin-bottom: 20px;
    }
    .transpoze-title {
        font-size: 26px;
        font-weight: bold;
    }
    .menu-container ul{
        list-style-type: none;
        padding: 0px;
        margin: 15px;
    }
    .menu-container ul li {
        padding: 10px;
        font-size: 15px;
        color: #6b6b6c;
        font-weight: 400;
        margin: 10px 0;
    }

    .menu-list .menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .menu-icon {
        line-height: 20px;
        gap: 13px;
        font-size: 15px;
    
    }
    .menu-list .menu-item .menu-dropdown{
    font-size: 11px;
    }
    .menu-item .menu-icon{
        display: flex;
        align-items: center;
    }

    .sub-list-item {
        padding: 4px 12px;
        position: relative;
        overflow: hidden;
        font-size: 16px;
        margin-left: 40px;
        width: 76%;
        border-left: 5px solid #F0F0F0;
        border-radius: 1px;
    }

    .sub-list-item-active {
        border-color: #EF805D !important;
        color: #EF805D !important;
    }
}
