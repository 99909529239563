.attendance-container {
    position: relative;

    .top-heading {
            background-color: #EF805D;
            border-radius: 4px;
            color: #FFFFFF;
            font-size: 24px;
            font-weight: 700;
            padding: 10px 52px;
            margin: auto 2px;
        }
    
        .filter-section {
            display: flex;
            height: 230px;
            margin: 33px 55px;
            padding: 50px 70px;
            background-color: white;
            border-radius: 30px;
            gap: 20px;
            align-items: flex-start;
    
            .filter-section-datefield {
                border-radius: 6px;
                color: #857E7E;
    
                .date-choose-btn {
                    background: #f8f8f8 !important;
                    padding: 15px;
                }
            }
    
            .filter-section-dropdown {
                display: flex;
    
                .dropdown-select {
                    background-color: #f8f8f8;
                    width: 220px;
                    height: 46px;
                    color: #857E7E;
                    font-size: 13px;
                    border-radius: 6px;
                    padding-left: 20px;
                    gap: 20px;
                }
            }
    
            .filter-section-icon {
                display: flex;
                margin-left: auto;
                margin-right: 50px;
                gap: 20px;
    
                .icon {
                    margin-left: 20px;
                    border: 1px solid #C1C1C1;
                    border-radius: 4px;
                    padding: 13px;
                    font-size: 18px;
                    color: #AFABAB;
                    cursor: not-allowed;
                }

                .enable-click{
                    cursor: pointer !important;
                }
    
                .export-text {
                    display: flex;
                    align-items: center;
                    column-gap: 7px;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: center;
                    background-color: #EF805D;
                    padding: 10px 13px;
                    color: white;
                    border-radius: 4px;

                    .down-icon {
                        background: #fff;
                        color: #AAA7A6;
                        font-size: 10px;
                        border-radius: 50%;
                        margin-top: 2px;
                        padding: 1px;
                    }
                }
            }
    
        }

        .attendance-status-container {
            position: relative;

            .attendance-content {
                position: absolute;
                background: #fff;
                padding: 32px 48px;
                border-radius: 20px;
                top: -115px;
                left: 16%;
                width: 70%;
                box-shadow: 2px 4px 14px 4px #E2ECF980;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .section-content {
                    display: flex;
                    align-items: center;
                    width: 32%;
                    padding: 0 15px;

                    &:not(:last-child) {
                        border-right: 1px solid #F0F0F0;
                    }

                    .section-icon {
                        width: 84px;
                        height: 84px;
                        background-image: linear-gradient(#F9D0C366, #F1D5CD);
                        margin-right: 20px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #EF805D;
                    }

                    .section-data {
                        .section-title {
                            font-size: 14px;
                            color: #ACACAC;
                            font-weight: 400;
                        }
                        .section-score {
                            font-size: 32px;
                            color: #333333;
                            font-weight: 600;
                            line-height: 1.3;
                        }
                        .section-growth {
                            font-style: 12px;
                            color: #292D32;

                            .upArrow + span {
                                color: #00B087;
                            }

                            .downArrow + span {
                                color: #EF805D;
                            }

                            span {
                                // color: #EF805D;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    
        .attendance-list-section {
            background-color: #FFFFFF;
            border-radius: 20px;
            padding: 34px 0;
            margin: 35px 55px;
    
            .attendance-list-section-heading {
                font-size: 22px;
                font-weight: 600;
                color: #151D48;
                margin-left: 20px;
            }
    
            .attendance-table-content {
                width: 100%;
                text-align: left;
                
                .attendance-table-header {

                     .table-row {
                        font-size: 14px;
                        color: #B5B7C0;
                        border-bottom: 1px solid #EEEEEE;

                        th {
                            padding-left: 30px;
                            padding-bottom: 17px;
                            padding-top: 17px;
                            font-weight: 500;

                            .header-content {
                                display: flex;
                                align-items: center;

                                .header-icon {
                                    width: 58px;
                                    height: 58px;
                                    margin-right: 12px;
                                }
                            }
                        }
                     }
                }
    
                .attendance-table-row {
                    font-size: 14px;
                    font-weight: 500;
                    padding: 20px;
                    color: #292D32;
                    border-bottom: 1px solid #EEEEEE;

                    td {
                        padding-left: 30px;
                        padding-bottom: 18px;
                        padding-top: 18px;
                        font-weight: 500;
                        color: #292D32;
                        font-weight: 500;

                        .user-name {
                            display: flex;
                            align-items: center;
                            column-gap: 5px;

                            .user-icon {
                                width: 40px;
                                height: 40px;
                                overflow: hidden;
                                border-radius: 50%;
                                background-color: #ddd;
                            }
                        }

                        .manual-attendance-line {
                            display: inline-block;
                            height: 1px;
                            width: 31px;
                            background: #E2E2E2;
                            margin-left: 10%;
                        }

                        .stu-present, .stu-absent, .stu-default {
                            font-size: 14px;
                            padding: 4px 19px;
                            display: inline-block;
                            border-radius: 4px;
                        }
                        .stu-present {
                            color: #008767;
                            background-color: #16C09861;
                            border: 1px solid #00B087;
                        }
                        .stu-absent{
                            color: #DF0404;
                            background: #FFC5C5;
                            border: 1px solid #DF0404;
                        }

                        .stu-default{
                            color: #71674d;
                            background: #dbff5b93;
                            border: 1px solid #797102;
                        }

                    }
                }
            }
        }

        .upArrow {
            transform: rotate(-90deg);
            display: inline-block;
            color: #00B087;
        }

        .downArrow {
            transform: rotate(90deg);
            display: inline-block;
            color: #FF4949;
        }
}