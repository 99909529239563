/* TeacherPerformance.css */
.teacher-performance {
    padding: 1rem;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.heading {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.teacher-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.teacher-photo {
    width: 3rem; /* Adjust size as needed */
    height: 3rem; /* Adjust size as needed */
    border-radius: 50%;
    margin-right: 0.5rem; /* Space between photo and name */
}

.teacher-name {
    font-weight: bold;
}

.circular-chart {
    margin-bottom: 1rem;
    height: 10rem; /* Adjust as needed */
    background-color: #f0f0f0; /* Placeholder color */
}

.performance-cards {
    display: flex;
    justify-content: space-between;
}

.performance-card {
    flex: 1; /* Equal width for each card */
    margin: 0 0.5rem; /* Space between cards */
    padding: 1rem;
    background: #e0e0e0; /* Card background */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.percentage {
    font-size: 1rem;
    font-weight: bold;
}

.candles {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Full width */
}

.candle {
    width: 0.5rem; /* Width of the candles */
    height: 1rem; /* Height of the candles */
    background: #34D399; /* Candle color */
    border-radius: 3px;
}
