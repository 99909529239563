.default-dashboard-container {
    @mixin flex-between-center {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    padding: 2rem;

    .dashboard-filters {
        @include flex-between-center;
        flex-wrap: wrap; // Allow the filters to wrap on smaller screens

        .search-date {
            display: flex;
            align-items: center;
            column-gap: 0.625rem; // 10px equivalent

            .input-dates {
                position: relative;
                flex: 1;
            }

            .input-label {
                &::before {
                    content: 'From Date';
                    position: absolute;
                    font-size: 0.625rem; // 10px
                    color: #aaa8a8;
                    display: inline-block;
                }
            }

            .input-dates:first-child .input-label:first-child::before {
                top: 0;
                left: 0;
            }

            .input-dates:last-child .input-label:first-child::before {
                content: 'To Date';
                top: 0;
                left: 0;
            }

            input {
                padding: 0.75rem 1.3125rem; // 12px 21px
                border-radius: 0.375rem; // 6px
                text-transform: uppercase;
                font-size: 0.8125rem; // 13px
            }
        }

        .filter-icons {
            @include flex-between-center;
            flex-wrap: wrap; // Allow icons to wrap

            div {
                text-align: center;
                padding: 0.75rem; // 12px
                font-size: 1.25rem; // 20px
                background: #fff;
                border: 0.0625rem solid #C1C1C1; // 1px
                border-radius: 0.25rem; // 4px
                margin-right: 1.25rem; // 20px
                color: #AFABAB;
                cursor: pointer;
            }
        }
    }

    .default-content-section {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column; // Stack on small screens
        gap: 1.875rem; // 30px

        .default-left-section {
            background-color: #fff;
            padding: 1.625rem; // 26px
            border-radius: 0.625rem; // 10px
            width: 100%;

            .section-title {
                font-size: 1.125rem; // 18px
                font-weight: bold;
                margin-bottom: 1.625rem; // 26px
            }

            .cards-list {
                display: flex;
                flex-wrap: wrap;
                gap: 1.625rem; // 26px

                .class-info-card {
                    width: 100%;
                    max-width: 20rem; // Limit width for responsiveness
                    cursor: pointer;

                    .class-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 1.1875rem; // 19px

                        .class-number {
                            width: 2.625rem; // 42px
                            height: 2.625rem;
                            line-height: 2.625rem;
                            text-align: center;
                            border-radius: 50%;
                            color: #fff;
                        }
                    }

                    .progress-bar-section {
                        margin-bottom: 1.125rem; // 18px

                        .progress-title {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 0.5rem; // 8px

                            span:first-child {
                                color: #ABA5A5;
                                font-size: 0.875rem; // 14px
                            }

                            span:last-child {
                                color: #000000;
                                font-weight: 500;
                                font-size: 1rem; // 16px
                            }
                        }

                        .emoji-icons {
                            display: flex;
                            align-items: center;
                        }
                    }

                    .card-footer-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .card-footer-info > div:first-child {
                            font-weight: bold;
                            color: #000000;
                        }
                    }
                }
            }
        }

        .default-right-section {
            padding: 1.25rem; // 20px
            background: #fff;
            border-radius: 0.625rem; // 10px
            width: 100%;
            max-width: 30%; // Responsive width

            .lead-board-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.625rem; // 26px

                .lead-board-title {
                    font-size: 1.375rem; // 22px
                    font-weight: 600;
                }
            }

            .lead-board-list-container {
                .lead-board-list-table {
                    width: 100%;

                    th {
                        font-size: 0.875rem; // 14px
                        color: #A3AED0;
                        font-weight: 500;
                        text-align: left;
                        padding: 0 0.9375rem 0.625rem 0; // 0 15px 10px 0
                    }

                    tr {
                        vertical-align: middle;

                        td {
                            font-size: 0.875rem; // 14px
                            color: #2B3674;
                            font-weight: 500;
                            text-align: left;
                            padding-right: 0.875rem; // 14px
                            height: 3.125rem; // 50px
                        }
                    }
                }
            }
        }
    }

    // Responsive adjustments for smaller screen sizes
    @media (max-width: 768px) {
        .dashboard-filters {
            flex-direction: column;
            align-items: flex-start;
        }

        .default-content-section {
            flex-direction: column;

            .default-left-section {
                width: 100%;
            }

            .default-right-section {
                width: 100%;
                max-width: none; // Remove max-width for mobile
            }

            .class-info-card {
                width: 100%; // Full width on mobile
            }
        }
    }
}
