.filter-container {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;

    .searchField {
        display: inline;
    }

    .search {
        width: 224px;
        padding: 12px;
        border: 1px solid #C1BCBC;
        border-radius: 70px;
        font-size: 11px;
        margin-left: 5px;
        font-weight: 400;
        color: #AFAFAF;
    }

    .dropdowns-section {
        display: flex;
        align-items: center;
    }

    .drop {
        background-color: white;
        color: #AFAFAF;
        border-radius: 6px;
        margin: 2px 4px;
        margin-left: 10px;
        font-size: 13px;

        label {
            font-size: 13px;
        }
    }

    .select-dropdowns {
        border: 1px solid #B0B0B0; /* Grey border line */
        width: 180px;  /* Width */
        height: 40px;  /* Height */
        padding: 8px;
        background: linear-gradient(500%, #f9f8f8, #f9f8f8 99%); /* Gradient background */
        color: #333; /* Text color */
        border-radius: 8px; /* Border radius */
        box-sizing: border-box; /* Ensures padding and border are included in width and height */
    }

    .select-dropdowns .dropdown-listbox { 
        border: 1px solid #C1BCBC; /* Adds border to dropdown options */
        border-radius: 8px; /* Adds border radius for box styling */
        padding: 8px; /* Adds padding inside the dropdown */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for a boxed effect */
    }

    .filter-icon {
        margin-left: 20px;
        border: 1px solid #C1C1C1;
        border-radius: 4px;
        padding: 10px 13px;
        font-size: 18px;
        color: #AFABAB;
        cursor: pointer;
    }
}