.sis-form-stepper-container {
    width: 30%;
    position: relative;

    .btn {
        display: inline;
        float: right;
        margin-right: 26px;
        margin-top: auto;
        border: none;
        background-color: inherit;
        color: white;
        font-size: 16px;
        text-decoration: underline;
        cursor: pointer;
    }

    .stu {
        list-style-type: none;
        background-color: #feede5;
        margin-top: auto;
        height: 80vh;
        min-height: 500px;
        padding-left: 0;
        position: relative;
    }

    .stu .stu1 {
        padding: 30px 0;
        display: flex;
        align-items: center;
        gap: 25px;
        margin-left: 20%;
    }

    .step-count {
        width: 60px;
        height: 60px;
        background: transparent;
        line-height: 60px;
        text-align: center;
        border-radius: 50%;
        border: 2px solid #ee805f;
        font-size: 30px;
        font-weight: bold;
        position: relative;
    }

    .step-name {
        font-size: 18px;
        font-weight: 600;
        color: #303972;
    }

    .stu1.active .step-count {
        background-color: #ee805f;
        color: #fff;
        box-shadow: inset 0px 1px 2px #3c3a3a;
    }

    .stu1:not(:last-child) .step-count::after {
        content: '';
        width: 2px;
        height: 60px;
        position: absolute;
        bottom: -60px;
        left: 50%;
        border-left: 2px dashed #ee805f;
        
    }
}

// .stepper-image {
//     height: 30%;
//     width: 30%; /* Increase width to extend to the right */
//     margin-top: 0%;
//     position: relative;
//     left: 47%; /* Adjust positioning to balance the extra width */
//    
// }
