.date-picker-container {
    position: relative;
    max-width: 90vw;

    .date-choose-btn {
        background: #fff !important;
        border-radius: 0.375rem; // 6px -> 0.375rem
        overflow: hidden;
    }

    .date-picker {
        position: absolute;
        z-index: 8;
        box-shadow: 0px 0.1875rem 0.5625rem #bebebe; // 3px and 9px -> rem
        border-radius: 0.375rem;
        overflow: hidden;
        margin-top: 0.5rem; // 8px -> 0.5rem
        margin-left: 1.5625rem; // 25px -> 1.5625rem
        background: #fff;
        width: auto;
        max-width: 90vw; // Ensure the date-picker adjusts for smaller screens

        .date-picker-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 0.5rem; // 8px -> 0.5rem
            padding: 0.625rem; // 10px -> 0.625rem

            .selected-dates {
                color: #6B7280;
                font-size: 0.875rem; // 14px -> 0.875rem
                font-weight: 400;
                margin-right: 0.625rem; // 10px -> 0.625rem
            }

            .apply-btn {
                background: #EF805D;
            }
        }
    }
}
