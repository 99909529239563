.filter-container {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;

    .searchField {
            display: inline;
        }
    
        .search {
            width: 224px;
            padding: 12px;
            border: 1px solid #C1BCBC;
            border-radius: 70px;
            font-size: 11px;
            margin-left: 5px;
            font-weight: 400;
            color: #AFAFAF;
        }
    
        .dropdowns-section {
            display: flex;
            align-items: center;
        }
    
        .drop {
            // width: 220px;
            // padding: 10px;
            background-color: white;
            color: #AFAFAF;
            // border: none;
            border-radius: 6px;
            margin: 2px 4px;
            margin-left: 10px;
            font-size: 13px;

            label {
                font-size: 13px;
            }
        }

        .select-dropdowns {
            border: none;
        }
    
        .filter-icon {
            margin-left: 20px;
            border: 1px solid #C1C1C1;
            border-radius: 4px;
            padding: 10px 13px;
            font-size: 18px;
            color: #AFABAB;
            cursor: pointer;
        }
}