.sis-form-container{
    padding: 50px;
    width: 70%;
    .main-heading{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .form-container{
        display: flex;
        justify-content: space-between;
    }
    .form-container .label-container .label-name{
            font-weight: bold;
            margin-Bottom : 9px;
    }
    .form-container .label-container .input-field{
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #ddd;
        width: 100%;
    }
    .form-container .label-container .input-dropdown{
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #ddd;
        width: 100%;
        background: white;
        color: gray;
    }
    .form-container  .button-container{
        display: flex;
        margin-top: 50px;
    }
    .form-container .button-container .button{
        padding: 15px;
        width: 140px;
        border: 1px solid #ed805f;
        background: white;
        color: #ed805f;
        border-radius: 6px;
    }
    .form-container .button-container .button-active{
        padding: 15px;
        margin-left: 10px;
        width: 140px;
        background: #ed805f;
        border: 1px solid #ed805f;
        color: white;
        border-radius: 6px;
    }
    .form-container .label-container{
        margin-top: 16px;
    }
}