.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 30px 40px;
    gap: 10px; /* Optional: Adds space between cards */
}

.card-item {
    flex: 1 1 calc(25% - 10px); /* 25% width for 4 cards in a row minus gap */
    margin: 10px;
    height: 300px; /* Set a fixed height for all cards */
    background-color: #F5F1FF; /* Example background color */
    border-radius: 8px; /* Optional: for rounded corners */
    overflow: hidden; /* Prevent content overflow */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Evenly distribute content within the card */
    align-items: center; /* Center content horizontally */
    box-shadow: -5px 5px 10px rgba(0,0,0,0.2); /* Optional: box shadow */
}

/* Responsive design for different screen sizes */
@media (max-width: 1200px) {
    .card-item {
        flex: 1 1 calc(33.33% - 10px); /* 3 cards in a row */
    }
}

@media (max-width: 900px) {
    .card-item {
        flex: 1 1 calc(50% - 10px); /* 2 cards in a row */
    }
}

@media (max-width: 600px) {
    .card-item {
        flex: 1 1 100%; /* 1 card per row */
    }
}

/* Styling for the card content */
.card-item .card-content {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center; /* Center align text */
    overflow: hidden; /* Prevent content overflow */
}

/* Ensure text is handled properly */
.card-item .card-content p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px; /* Reduced font size for content */
}

.card-item .card-content h2 {
    font-size: 14px; /* Reduced font size for headers */
    font-weight: 600;
}

