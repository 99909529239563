.card-container {
            padding: 16px;
            display: flex;
            margin-top: 30px;
            border-radius: 15px;
            box-shadow: 5px 5px #e48463;

            .card-title {
                font-weight: bold;
                font-size: 26px;
            }
.letter {
        font-size: 20px;
        margin: 4px;
        border: 1px solid #EF805D;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 36px;
        background-color: #feded8;
        font-weight: 600;
        cursor: pointer;
    }

    .totalStudentsText {
        font-size: 13px;
    }

    .section-tag {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
    }

    .class-count {
        font-size: 20px;
        font-weight: bold;
    }
}
