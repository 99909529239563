.button-container{
    display: flex;
    margin-top: 30px;
    justify-content: end;
}
.button-container .button{
    padding: 15px;
    width: 140px;
    border: 1px solid #ed805f;
    background: white;
    color: #ed805f;
    border-radius: 6px;
}
.button-container .button-active{
    padding: 15px;
    margin-left: 10px;
    width: 140px;
    background: #ed805f;
    border: 1px solid #ed805f;
    color: white;
    border-radius: 6px;
}