.admin-bar-container {
    padding: 2rem 1.5rem; // Adjusted to rem
    display: flex;
    justify-content: space-between;
    align-items: center; // Ensure vertical centering
    background: #fff;

    .selected-section-title {
        font-size: 2rem; // Adjusted to rem
        font-weight: bold;
        flex: 1; // Allow title to take available space
    }

    .admin-right-section {
        display: flex;
        align-items: center;
        margin-left: 2rem; // Adjusted to rem

        .notification-icon {
            width: 2.5rem; // Adjusted to rem
            height: 2.5rem;
            line-height: 2.5rem;
            background: #fefaf1;
            text-align: center;
            margin-right: 1.5rem; // Adjusted to rem
            border-radius: 0.5rem; // Adjusted to rem
        }

        .user-section {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            .user-image {
                width: 3.125rem; // Adjusted to rem
                height: 3.125rem;
                background: #ddd;
                border-radius: 50%;
                margin-right: 1rem; // Adjusted to rem
                overflow: hidden;

                img {
                    width: inherit;
                    height: inherit;
                }
            }

            .user-details {
                display: flex;
                flex-direction: column; // Keep details in a column
                margin-right: 0.5rem; // Adjusted to rem

                .user-name {
                    font-size: 1rem; // Adjusted to rem
                }

                .user-type {
                    font-size: 0.875rem; // Adjusted to rem
                    color: #9b9696;
                }
            }

            .dropdown-menu {
                position: absolute;
                top: 100%; // Positioning it below the user section
                right: 0;
                background-color: #fff;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                border-radius: 0.25rem; // Adjusted to rem
                overflow: hidden;
                z-index: 10;
                display: block; // Set to block since we're using conditional rendering
                min-width: 10rem; // Optional: set a minimum width for dropdown
            }
            

            .user-section:hover .dropdown-menu {
                display: block; // Show dropdown on hover
            }

            .dropdown-item {
                padding: 0.625rem 1.25rem; // Adjusted to rem
                font-size: 0.875rem; // Adjusted to rem
                cursor: pointer;
                transition: background-color 0.2s;
            }

            .dropdown-item:hover {
                background-color: #f1f1f1;
            }
        }
    }

    /* Media queries for responsiveness */
    @media (max-width: 48rem) { // About 768px
        flex-direction: row; // Maintain horizontal alignment
        padding: 1rem; // Adjust padding for smaller screens

        .selected-section-title {
            font-size: 1.5rem; // Smaller title for mobile
            margin-right: 1rem; // Space below title
        }

        .admin-right-section {
            margin-left: 1rem; // Adjust margin for smaller screens

            .user-details {
                .user-name {
                    display: none; // Hide name on smaller screens
                }
            }
        }
    }

    @media (max-width: 30rem) { // About 480px
        .selected-section-title {
            font-size: 1.25rem; // Further reduce title size for smaller screens
        }

        .admin-right-section {
            .user-section {
                margin-top: 0.5rem; // Space above user section
                width: auto; // Allow section to fit content
            }

            .dropdown-menu {
                width: 100%; // Full width for dropdown on mobile
                right: 0; // Align to the right edge
            }
        }
    }
}
